import positionReportApi from '@/api/positionReport.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
// import { DialogType } from '@/constants/constants'
import { getDateTimeRange, dateSortFunction } from '@/tools/functions'
import { SnackbarStyle } from '@/constants/constants'
import i18n from '@/i18n'
import { configurationService } from '@/business/configurationService'
import { personService } from '@/business/personService'
import { eventService } from '@/business/eventService'
import service from '@/middleware'
import positionReportService from '../../../business/positionReportService'

export default {
  name: 'PositionDetailReport',
  components: {
  },
  data: () => ({
    tableHeaders: [],
    tableCustomHeaders: [],
    tableFilterRules: {},
    extraInformation: {},
    tableData: [],
    tableDataKey: 'number',
    mapData: [],
    mapConfiguration: [],
    loadingSpinner: true,
    loadingTable: true,
    disableRefreshTable: true,
    timeStampFrom: undefined,
    timeStampTo: undefined,
    tableConfig: {},
    middleware: service.http,
    highlightRow: null,
    queryPageSize: 100,
    queryPageNumber: 1,
    pageTotal: undefined,
    tableDataTemp: []
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    })
  },
  created () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getConfig()
    if (this.selectedItem) {
      this.getData(this.selectedItem)
    }
  },
  beforeDestroy () {
    this.closeReportDefer()
    this.resetEntitySelector()
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    ...mapMutations('reportDefer', {
      showSnackbar: 'showSnackbar',
      closeSnackbar: 'closeSnackbar',
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapActions('snackbar', {
      showSnackbar: 'showSnackbar',
      closeSnackbar: 'closeSnackbar'
    }),
    /**
     * cuando se hace click en un marcador del mapa, se resalta la fila en la tabla
     * @param {*} param0
     */
    onMapClick ({ number }) {
      if (number != null) {
        this.highlightRow = null
        const that = this
        this.$nextTick(() => { that.highlightRow = number })
      }
    },
    refreshTable () {
      if (this.selectedItem) {
        this.getData(this.selectedItem, true)
      }
    },
    getData (data, refresh = false) {
      const that = this
      if (data.autoReport) {
        const conversion = getDateTimeRange(data.dateTimeRange, data.customDateTimeRangeType)
        that.timeStampFrom = conversion.tFrom
        that.timeStampTo = conversion.tTo
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        that.loadingTable = true
        that.disableRefreshTable = false
        positionReportApi.getPositionReportDetail([data.thingId], that.timeStampFrom, that.timeStampTo).then(async response => {
          const report = await positionReportService.processSummaryDataDetails(response.data, that.timeStampFrom, that.timeStampTo)
          if (!refresh) {
            const persons = await personService.getPersonMap()
            const events = await eventService.getEventsMapNameByThingsIds([data.thingId])
            const internalEvents = await eventService.getInternalEventDescriptionMap()
            this.extraInformation['drivers'] = persons
            this.extraInformation['events'] = events
            this.extraInformation['internalEvents'] = internalEvents
            this.tableHeaders.cleanAndUpdate(report.headers)
            this.tableCustomHeaders = report.customHeaders
            this.setTableFilterRules(report.headers)
            this.setSortFunctions()
          }
          this.tableDataTemp = report.data || []
          this.pageTotal = report.data.length
          this.tableData.cleanAndUpdate(this.getPaginatorArray(this.tableDataTemp, this.pageSize, this.pageNumber))
          this.mapData.cleanAndUpdate(report.data)
          this.mapConfiguration = report.maps
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          that.loadingTable = false
        })
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            const conversion = getDateTimeRange(data.dateTimeRange, data.customDateTimeRangeType)
            that.timeStampFrom = conversion.tFrom
            that.timeStampTo = conversion.tTo
            if (that.loadingSpinner) that.commitVisible({ visible: true })
            that.loadingTable = true
            positionReportApi.getPositionReportDetail([data.thingId], that.timeStampFrom, that.timeStampTo).then(async response => {
              const report = await positionReportService.processSummaryDataDetails(response.data, that.timeStampFrom, that.timeStampTo)
              this.tableDataTemp = report.data || []
              this.pageTotal = report.data.length
              this.tableData.cleanAndUpdate(this.getPaginatorArray(this.tableDataTemp, this.queryPageSize, this.queryPageNumber))
              that.mapData.cleanAndUpdate(report.data)
              that.mapConfiguration = report.maps
              if (that.loadingSpinner) that.commitVisible({ visible: false })
              that.loadingTable = false
            })
          },
          data.autoReportMinutes * 60 * 1000)
      } else {
        if (!refresh) {
          if (this.loadingSpinner) this.commitVisible({ visible: true })
          that.loadingTable = true
          positionReportApi.getPositionReportDetail([data.thingId], data.timeStampFrom, data.timeStampTo).then(async response => {
            const report = await positionReportService.processSummaryDataDetails(response.data, that.timeStampFrom, that.timeStampTo)
            const persons = await personService.getPersonMap()
            const events = await eventService.getEventsMapNameByThingsIds([data.thingId])
            const internalEvents = await eventService.getInternalEventDescriptionMap()
            this.extraInformation['drivers'] = persons
            this.extraInformation['events'] = events
            this.extraInformation['internalEvents'] = internalEvents
            this.tableHeaders.cleanAndUpdate(report.headers)
            this.tableCustomHeaders = report.customHeaders
            this.setTableFilterRules(report.headers)
            this.setSortFunctions()
            this.tableDataTemp = report.data || []
            this.pageTotal = this.tableDataTemp.length
            this.tableData.cleanAndUpdate(this.getPaginatorArray(this.tableDataTemp, this.queryPageSize, this.queryPageNumber))
            this.mapData.cleanAndUpdate(report.data)
            this.mapConfiguration = report.maps
            if (this.loadingSpinner) this.commitVisible({ visible: false })
            that.loadingTable = false
          })
        }
      }
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    dataChangeEventHandler (newData) {
      this.mapData.cleanAndUpdate(newData)
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY') }
      this.tableHeaders.find(header => header.value === 'time').sort = (a, b) => { return dateSortFunction(a, b, 'HH:mm:ss') }
    },
    /**
     * Para cargar la configuración de la tabla
     */
    async getConfig () {
      const config = await configurationService.get('position-detail-report')
      this.tableConfig = config && config.data ? config.data : {}
    },
    /**
     * Para guardar la configuración de la tabla
     * @param {*} config
     */
    saveConfig (config) {
      configurationService.save('position-detail-report', config)
        .then(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
          this.getConfig()
        }).catch(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
        })
    },
    /**
     * Centrar el mapa luego de hacer click en una fila de la tabla
     * Esto se copió de otros reportes que tienen la misma función
     * TO DO: implementar en el componente MapComponent una prop reqactiva o modelo y un watch para ejecutar el centrado y así no usar referencias al DOM físico
     * @param {*} selectedItem
     */
    centerMap ({ position }) {
      const mapRef = this.$refs.PositionDetailMapComponent
      if (mapRef != null && position != null) {
        mapRef.centerMap(JSON.parse(position))
      }
    },
    externalPaginator (pageSize, pageNumber) {
      this.queryPageNumber = Number(pageNumber)
      this.queryPageSize = Number(pageSize)
      this.tableData.cleanAndUpdate(
        this.getPaginatorArray(this.tableDataTemp, pageSize, pageNumber)
      )
    },
    getPaginatorArray (arrayData, pageSize, pageNumber) {
      return arrayData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
    },
    // Para implementar una vez se guarden las configuraciones de los filtros
    mapResetFilter () {
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) {
        this.getData(this.selectedItem)
      }
    }
  }
}
