import { FilterType, DataType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const REPORT_CONFIGURATION_KEYS = {
  POSITION: 'POSITION'
}

const NUMERIC_HEADERS = [
  'Edad Dato',
  'Nivel Señal',
  'Temperatura',
  'RPM Motor',
  'Tension Bateria',
  'RPM',
  'Sensor Temperatura 1',
  'Sensor Temperatura 2'
]

const headers = (lang, isForDetail = false) => {
  const headers = [
    {
      text: i18n.t('harvesterReport.dashboard.headers.date', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'date'
    },
    {
      text: i18n.t('harvesterReport.dashboard.headers.hour', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'time'
    },
    {
      text: i18n.t('positionReport.communicationDate', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'communicationDate'
    },
    {
      text: i18n.t('positionReport.communicationTime', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'communicationTime'
    },
    {
      text: i18n.t('harvesterReport.dashboard.headers.speed', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'speed',
      width: 180
    },
    {
      text: i18n.t('harvesterReport.dashboard.headers.position', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.array,
      value: 'position'
    },
    {
      text: i18n.t('harvesterReport.dashboard.headers.fromThingName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'fromThingName'
    }
  ]

  return isForDetail
    ? [{
      text: i18n.t('harvesterReport.dashboard.headers.number', { lang }),
      align: 'right',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'number'
    },
    ...headers]
    : headers
}

export default {
  REPORT_CONFIGURATION_KEYS,
  NUMERIC_HEADERS,
  headers
}
