import positionReportApi from '@/api/positionReport.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapState, mapMutations, mapGetters } from 'vuex'
// import { DialogType } from '@/constants/constants'
import { getDateTimeRange, dateSortFunction } from '@/tools/functions'
import { SnackbarStyle } from '@/constants/constants'
import i18n from '@/i18n'
import { configurationService } from '@/business/configurationService'
import service from '@/middleware'
import { personService } from '@/business/personService'
import { eventService } from '@/business/eventService'
import positionReportService from '../../../business/positionReportService'

export default {
  name: 'PositionSimpleReport',
  components: {
  },
  data: () => ({
    tableHeaders: [],
    tableCustomHeaders: [],
    tableFilterRules: {},
    extraInformation: {},
    tableData: [],
    tableDataKey: 'id',
    loadingSpinner: true,
    loadingTable: false,
    disableRefreshTable: true,
    timeStampFrom: undefined,
    timeStampTo: undefined,
    tableConfig: {},
    middleware: service.http
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    })
  },
  created () {
  },
  async beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getConfig()
    if (this.selectedItem) {
      this.getData(this.selectedItem)
    }
  },
  beforeDestroy () {
    this.closeReportDefer()
    this.resetEntitySelector()
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('reportDefer', {
      showSnackbar: 'showSnackbar',
      closeSnackbar: 'closeSnackbar',
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    refreshTable () {
      if (this.selectedItem) {
        this.getData(this.selectedItem, true)
      }
    },
    resize () {
    },
    getData (data, refresh = false) {
      const that = this
      if (data.autoReport) {
        const conversion = getDateTimeRange(data.dateTimeRange, data.customDateTimeRangeType)
        that.timeStampFrom = conversion.tFrom
        that.timeStampTo = conversion.tTo
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        that.loadingTable = true
        that.disableRefreshTable = false
        positionReportApi.getPositionReportDetail([data.thingId], that.timeStampFrom, that.timeStampTo).then(async response => {
    
          const report = await positionReportService.processSummaryDataDetails(response.data, this.timeStampFrom, this.timeStampTo)
          if (!refresh) {
            const persons = await personService.getPersonMap()
            const events = await eventService.getEventsMapNameByThingsIds([data.thingId])
            const internalEvents = await eventService.getInternalEventDescriptionMap()
            this.extraInformation['drivers'] = persons
            this.extraInformation['events'] = events
            this.extraInformation['internalEvents'] = internalEvents
            this.tableHeaders.cleanAndUpdate(report.headers)
            this.tableCustomHeaders = report.customHeaders
            this.setTableFilterRules(report.headers)
            this.setSortFunctions()
          }
          this.tableData.cleanAndUpdate(report.data)
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          that.loadingTable = false
        })
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            const conversion = getDateTimeRange(data.dateTimeRange, data.customDateTimeRangeType)
            that.timeStampFrom = conversion.tFrom
            that.timeStampTo = conversion.tTo
            if (that.loadingSpinner) that.commitVisible({ visible: true })
            that.loadingTable = true
            positionReportApi.getPositionReportDetail([data.thingId], that.timeStampFrom, that.timeStampTo).then(async response => {
              const report = await positionReportService.processSummaryDataDetails(response.data, this.timeStampFrom, this.timeStampTo)
              that.tableData.cleanAndUpdate(report.data)
              if (that.loadingSpinner) that.commitVisible({ visible: false })
              that.loadingTable = false
            })
          },
          data.autoReportMinutes * 60 * 1000)
      } else {
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        that.loadingTable = true
        positionReportApi.getPositionReportDetail([data.thingId], data.timeStampFrom, data.timeStampTo).then(async response => {
          const report = await positionReportService.processSummaryDataDetails(response.data, this.timeStampFrom, this.timeStampTo)
          const persons = await personService.getPersonMap()
          const events = await eventService.getEventsMapNameByThingsIds([data.thingId])
          const internalEvents = await eventService.getInternalEventDescriptionMap()
          this.extraInformation['drivers'] = persons
          this.extraInformation['events'] = events
          this.extraInformation['internalEvents'] = internalEvents
          this.tableHeaders.cleanAndUpdate(report.headers)
          this.tableCustomHeaders = report.customHeaders
          this.setTableFilterRules(report.headers)
          this.setSortFunctions()
          this.tableData.cleanAndUpdate(report.data)
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          that.loadingTable = false
        })
      }
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    clickRow () {
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY') }
      this.tableHeaders.find(header => header.value === 'time').sort = (a, b) => { return dateSortFunction(a, b, 'HH:mm:ss') }
    },
    /**
     * Para cargar la configuración de la tabla
     */
    async getConfig () {
      const config = await configurationService.get('position-detail-report')
      this.tableConfig = config && config.data ? config.data : {}
    },
    /**
     * Para guardar la configuración de la tabla
     * @param {*} config
     */
    saveConfig (config) {
      configurationService.save('position-detail-report', config)
        .then(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
          this.getConfig()
        }).catch(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
        })
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) {
        this.getData(this.selectedItem)
      }
    }
  }
}
