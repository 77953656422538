import Vue from 'vue'
import i18n from '@/i18n'
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import PositionDetailReport from './detail/PositionDetailReport.vue'
import PositionSimpleReport from './simple/PositionSimpleReport.vue'
import positionReportApi from '@/api/positionReport.api'
import thingApi from '@/api/thing.api'
import sectorApi from '@/api/sector.api'
import reportStorageApi from '@/api/report-storage.api'
import { getChildrenFromList, getDateTimeRange, selectorDateTimeValidation, ISODateFromUnixTimestamp } from '@/tools/functions'
import moment from 'moment'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import { configurationService } from '@/business/configurationService'
import service from '@/middleware'
import selectorService from '@/business/selectorService'
import positionReportService from '../../business/positionReportService'

export default {
  name: 'PositionReport',
  components: {
    SelectorComponent,
    BreadcrumbComponent,
    PositionDetailReport,
    PositionSimpleReport
  },
  props: {
    mapInfo: {
      type: Object,
      required: false,
      default: () => { return undefined }
    }
  },
  data: () => ({
    componentId: 'position-report',
    completeReport: false,
    simpleReport: false,
    tabsPosition: [
      {
        id: 'tab-position',
        name: i18n.t('dashboard.positionReport')
      }
    ],
    selectedTabPosition: 'tab-position',
    breadcrumbButtomsPosition: [],
    barChart: [],
    pieChart: [],
    map: undefined,
    polylines: undefined,
    markers: undefined,
    polygons: undefined,
    chartConfiguration: [],
    tableData: [],
    mapConfiguration: JSON.stringify([]),
    mapConfigToSend: undefined,
    showSelector: false,
    selectorDisabled: false,
    selectorModel: selectorService.getSelectorModel(true),
    sectorFilter: {
      id: 'sector',
      name: i18n.t('headers.sector'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    thingFilter: {
      id: 'thing',
      name: i18n.t('headers.thing'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: []
    },
    reportTypeSwitch: {
      value: true,
      name: i18n.t('position.reporType'),
      textTrue: i18n.t('position.compose'),
      textFalse: i18n.t('position.typeSimple'),
      show: false
    },
    filterSwitch: true,
    sectors: [],
    thingsId: [],
    things: [],
    dateAndTimeRange: TimeRanges.LAST_HOUR,
    dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    // Para guardar los timestamps del selector de fechas
    timeStampFrom: moment().unix(),
    timeStampTo: moment().subtract(1, 'hours').unix(),
    autoReport: false,
    autoReportMinutes: 10,
    loadingSpinner: true,
    loadingSpinnerDoughnut: false,
    intervalId: null,
    chartComponentModel: {
      showRangeSelector: true,
      rangeSelectorData: [
        [
          {
            intervalStart: 0,
            intervalEnd: 50,
            color: '#FF0000'
          },
          {
            intervalStart: 50,
            intervalEnd: 100,
            color: '#00FF00'
          },
          {
            intervalStart: 100,
            intervalEnd: undefined,
            color: '#0000FF'
          }
        ]
      ]
    },
    middleware: service.http,
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('dashboard.positionReport')}</b>` }]
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    ...mapGetters([
      'getFirebaseToken'
    ]),
    ...mapState('app', {
      selectedItem: state => state.selectedItem
    })
  },
  async mounted() {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Inicializo los datos del selector
    await this.initializeSelector()
    await this.getConfiguration()
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  created() {
    this.breadcrumbButtomsPosition.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: this.showSelectorButton.bind(this),
        disable: this.disableSelectorButton.bind(this),
        function: this.showSelectorMethod.bind(this)
      })
  },
  beforeDestroy() {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  methods: {
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapMutations('reportDefer', {
      showSnackbar: 'showSnackbar',
      closeSnackbar: 'closeSnackbar',
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    /**
     * Inicializador de los datos del selector
     */
    async initializeSelector() {
      // deshabilitar time format
      this.selectorModel.showTimeFormatSelector = false
      this.selectorModel.filters.push(this.sectorFilter, this.thingFilter)
      this.selectorModel.switches.push(this.reportTypeSwitch)
      // callback cuando se seleccionan items en los filtros
      this.sectorFilter.selectAction = this.sectorFilterCallback.bind(this)
      // datos para el selector de sectores
      const sectorResponse = await sectorApi.getAll()
      this.sectorFilter.data.cleanAndUpdate(sectorResponse.data)
      if (sectorResponse.data.length === 1 && sectorResponse.data[0].key === 'NO_SECTOR') {
        this.sectorFilter.selectedData = this.sectorFilter.data.filter(s => s.key === 'NO_SECTOR')
        this.sectorFilter.disabled = true
        this.sectorFilter.hide = true
        this.sectorFilterCallback()
      }
    },
    async sectorFilterCallback() {
      if (this.sectorFilter.selectedData.length > 0) {
        this.thingFilter.selectedData = []
        this.thingFilter.disabled = false
        // datos para el selector de cosas
        const response = await thingApi.getWorkFrontsNested(this.sectorFilter.selectedData[0].key)
        this.thingFilter.data.cleanAndUpdate(response.data)
      } else {
        this.thingFilter.selectedData = []
        this.thingFilter.disabled = true
      }
    },
    loadSimpleReport(refresh = false) {
      this.tabsPosition.cleanAndUpdate(this.tabsPosition.filter(tab => tab.id !== 'tab-position-detail'))
      this.selectedTabPosition = 'tab-position'

      this.setEntityConfiguration({ name: this.$t('things'), value: 'thingId', text: 'thingName' })
      const items = []
      this.tableData.forEach(thing => {
        if (!items.some(item => item.thingId === thing._id)) {
          items.push(
            {
              thingId: thing._id,
              thingName: thing.name,
              timeStampFrom: this.timeStampFrom,
              timeStampTo: this.timeStampTo,
              dateTimeRange: this.dateAndTimeRange,
              customDateTimeRangeType: this.dateAndTimeRangeCustomType,
              autoReport: this.autoReport,
              autoReportMinutes: this.autoReportMinutes,
              position: thing.position
            })
        }
      })

      this.setEntitySelectorItems(items)
      if (items.length > 0 && !refresh) {
        this.setSelectedItem(items[0])
      } else if (refresh) {
        this.setSelectedItem(this.selectedItem)
      }
      this.commitVisible({ visible: false })
      this.loadingSpinner = false
    },
    clickAction(data) {
      this.setEntityConfiguration({ name: this.$t('things'), value: 'thingId', text: 'thingName' })
      const item = {
        thingId: data._id,
        thingName: data.name,
        timeStampFrom: this.timeStampFrom,
        timeStampTo: this.timeStampTo,
        dateTimeRange: this.dateAndTimeRange,
        customDateTimeRangeType: this.dateAndTimeRangeCustomType,
        autoReport: this.autoReport,
        autoReportMinutes: this.autoReportMinutes
      }
      const items = [item]
      this.tableData.forEach(thing => {
        if (!items.some(item => item.thingId === thing._id)) {
          items.push(
            {
              thingId: thing._id,
              thingName: thing.name,
              timeStampFrom: this.timeStampFrom,
              timeStampTo: this.timeStampTo,
              dateTimeRange: this.dateAndTimeRange,
              customDateTimeRangeType: this.dateAndTimeRangeCustomType,
              autoReport: this.autoReport,
              autoReportMinutes: this.autoReportMinutes
            })
        }
      })

      this.setEntitySelectorItems(items)
      this.setSelectedItem(item)
      if (!this.tabsPosition.find(tab => tab.id === 'tab-position-detail')) {
        this.tabsPosition.push({
          id: 'tab-position-detail',
          name: i18n.t('detail')
        })
      }
      this.commitVisible({ visible: false })
      this.loadingSpinner = false
      this.selectedTabPosition = 'tab-position-detail'
    },
    resize() {
      this.$eventBus.$emit('resizeCharts')
    },
    async getData(reportKey) {
      const firstChartComponentRef = this.$refs['firstChartComponent']
      const secondChartComponentRef = this.$refs['secondChartComponent']
      if (reportKey) {
        this.selectorDisabled = false
        this.showSelector = false
        const response = await reportStorageApi.getReport(reportKey)
        const { data, filters } = response.data
        const processedSummary = await positionReportService.processSummaryData(data, filters.from, filters.to, filters.ranges)
        this.tableData.cleanAndUpdate(processedSummary.data)
        if (filters) this.setFilterData(filters)
        if (filters.completeReport) {
          this.barChart.cleanAndUpdate(processedSummary.chartDistance)
          if (firstChartComponentRef) {
            this.barChart.forEach(c => {
              firstChartComponentRef.reset(c)
            })
          }
          this.pieChart.cleanAndUpdate(processedSummary.chartVelocity)
          if (secondChartComponentRef) {
            this.pieChart.forEach(c => {
              secondChartComponentRef.reset(c)
            })
          }
          this.mapConfiguration = JSON.stringify(processedSummary.maps)
          this.mapConfigToSend = JSON.parse(this.mapConfiguration)
          this.completeReport = true
        } else {
          this.timeStampFrom = filters.from
          this.timeStampTo = filters.to
          this.loadSimpleReport()
          this.simpleReport = true
        }
      } else {
        this.selectorDisabled = false
        this.showSelector = true
      }
    },
    selector() {
      this.showSelector = !this.showSelector
    },
    getReport(eventData) {
      this.filterSwitch = eventData.filterSwitchValue
      this.completeReport = false
      this.simpleReport = false
      const firstChartComponentRef = this.$refs['firstChartComponent']
      const secondChartComponentRef = this.$refs['secondChartComponent']

      if (eventData.filterSwitchValue) {
        if (this.sectorFilter.hide) {
          this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        } else {
          this.sectors.clean()
        }
        this.things = []
        getChildrenFromList(eventData.groupEquipmentFilterSelected, this.things)
      } else {
        this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        this.things = []
        getChildrenFromList(this.thingFilter.selectedData, this.things)
      }
      this.thingsId = this.things.map(t => t.id)

      this.dateAndTimeRange = eventData.dateAndTimeRange
      this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
      this.sinceDate = eventData.sinceDate
      this.sinceTime = eventData.sinceTime
      this.toDate = eventData.toDate
      this.toTime = eventData.toTime
      this.autoReport = eventData.autoReport
      this.autoReportMinutes = eventData.autoReportMinutes

      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo

      const that = this
      if (this.thingsId.length > 0) {
        if (eventData.autoReport) {
          if (this.loadingSpinner) this.commitVisible({ visible: true })
          positionReportApi.getPositionReportNotDeferred(
            this.thingsId, this.timeStampFrom, this.timeStampTo, this.sectors,
            this.chartComponentModel.rangeSelectorData[0])
            .then(async response => {
              const { data } = response
              const processedSummary = await positionReportService.processSummaryData(data, this.timeStampFrom, this.timeStampTo, this.chartComponentModel.rangeSelectorData[0])
              this.tableData.cleanAndUpdate(processedSummary.data)
              if (this.reportTypeSwitch.value) {
                this.barChart.cleanAndUpdate(processedSummary.chartDistance)
                if (firstChartComponentRef) {
                  this.barChart.forEach(c => {
                    firstChartComponentRef.reset(c)
                  })
                }
                this.pieChart.cleanAndUpdate(processedSummary.chartVelocity)
                if (secondChartComponentRef) {
                  this.pieChart.forEach(c => {
                    secondChartComponentRef.reset(c)
                  })
                }
                this.mapConfiguration = JSON.stringify(processedSummary.maps)
                this.mapConfigToSend = JSON.parse(this.mapConfiguration)
                this.completeReport = true
              } else {
                this.loadSimpleReport()
                this.simpleReport = true
              }
              if (this.loadingSpinner) this.commitVisible({ visible: false })
              this.setReportDetails()
            })
          clearInterval(this.intervalId)
          this.intervalId = setInterval(
            function () {
              if (that.loadingSpinner) that.commitVisible({ visible: true })
              positionReportApi.getPositionReportNotDeferred(
                that.thingsId, that.timeStampFrom, that.timeStampTo, that.sectors,
                that.chartComponentModel.rangeSelectorData[0])
                .then(async response => {
                  const { data } = response
                  const processedSummary = await positionReportService.processSummaryData(data, that.timeStampFrom, that.timeStampTo, that.chartComponentModel.rangeSelectorData[0])
                  that.tableData.cleanAndUpdate(processedSummary.data)
                  if (that.reportTypeSwitch.value) {
                    that.barChart.cleanAndUpdate(processedSummary.chartDistance)
                    if (firstChartComponentRef) {
                      that.barChart.forEach(c => {
                        firstChartComponentRef.reset(c)
                      })
                    }
                    that.pieChart.cleanAndUpdate(processedSummary.chartVelocity)
                    if (secondChartComponentRef) {
                      that.pieChart.forEach(c => {
                        secondChartComponentRef.reset(c)
                      })
                    }
                    that.mapConfiguration = JSON.stringify(processedSummary.maps)
                    that.mapConfigToSend = JSON.parse(that.mapConfiguration)
                  } else {
                    that.loadSimpleReport(true)
                  }
                  if (that.loadingSpinner) that.commitVisible({ visible: false })
                })
            },
            eventData.autoReportMinutes * 60 * 1000)
        } else {
          positionReportService.getPositionReportDeferred(
            this.thingsId, this.things, this.timeStampFrom, this.timeStampTo,
            eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
            eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime,
            this.sectors, this.reportTypeSwitch.value, this.filterSwitch,
            this.chartComponentModel.rangeSelectorData[0], this.$route.path)
          this.showReportDefer({ updateFunction: this.getData.bind(this) })
        }
      }
      this.showSelector = false
    },
    /**
    * SIMULA EL RESET HASTA QUE SE PERSISTAN LAS CONFIGURACIONES PARA EL REPORTE DE POSICIONES  (idMap, groupName, references, mapsId)
    */
    mapResetFilter() {
      this.mapConfigToSend = JSON.parse(this.mapConfiguration)
    },
    validateSelector() {
      const that = this
      Vue.nextTick(function () {
        const thingsSelected = that.selectorModel.showGroupEquipmentFilter && that.selectorModel.filterSwitch.value
          ? that.selectorModel.groupEquipmentFilter.selectedData
          : that.thingFilter.selectedData
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
        that.selectorModel.disableGetReportButton = thingsSelected.length === 0 || customTimeValidation
      })
    },
    /**
     * TO DO: inicializar los datos de fechas y horas en el selector
     * @param {*} filterData
     */
    async setFilterData(filterData) {
      if (filterData) {
        // switch de tipo de filtrado
        this.selectorModel.filterSwitch.value = filterData.filterSwitch
        this.filterSwitch = filterData.filterSwitch

        // Filtro de sectores
        if (this.sectorFilter.data.length > 0 && filterData.sector && filterData.sector.length > 0) {
          this.sectorFilter.selectedData = filterData.sector
          await this.sectorFilterCallback()
        }
        this.sectors = filterData.sector

        // Filtro de equipos
        this.thingsId = filterData.thingIds
        this.things = filterData.things

        if (this.filterSwitch) {
          this.things.forEach(thing => {
            if (this.selectorModel.groupEquipmentFilter.data.find(t => (t.id === thing.id || (t.children && t.children.length > 0 && t.children.find(child => (child.id === thing.id)))) && !this.selectorModel.groupEquipmentFilter.selectedData.find(t => t.id === thing.id))) {
              this.selectorModel.groupEquipmentFilter.selectedData.push(thing)
            }
          })
        } else {
          this.things.forEach(thing => {
            if (this.thingFilter.data.find(t => (t.id === thing.id || (t.children && t.children.length > 0 && t.children.find(child => (child.id === thing.id)))) && !this.thingFilter.selectedData.find(t => t.id === thing.id))) {
              this.thingFilter.selectedData.push(thing)
            }
          })
        }

        this.timeStampFrom = filterData.from
        this.timeStampTo = filterData.to
        this.selectorModel.selectedDateAndTimeRange = filterData.dateTimeRange
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.selectorModel.sinceDate = filterData.sinceDate
        this.selectorModel.sinceTime = filterData.sinceTime
        this.selectorModel.toDate = filterData.toDate
        this.selectorModel.toTime = filterData.toTime
        this.dateAndTimeRange = filterData.dateTimeRange
        this.dateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.sinceDate = filterData.sinceDate
        this.sinceTime = filterData.sinceTime
        this.toDate = filterData.toDate
        this.toTime = filterData.toTime

        this.reportTypeSwitch.value = filterData.completeReport

        this.setCustomDateAndTimeFilter(filterData)
        this.setReportDetails()
      }
    },
    setCustomDateAndTimeFilter(filterData) {
      if (filterData.from && filterData.to) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        } else if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM &&
          this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
          this.selectorModel.sinceTime = null
          this.selectorModel.toTime = null
        } else {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    setReportDetails() {
      const detailData = {
        from: this.timeStampFrom,
        to: this.timeStampTo,
        things: this.things,
        sector: this.sectors,
        reportName: this.reportNameForChart
      }
      const dataStructure = [
        { detailDataProp: 'reportName', propTitle: this.$t('report'), propType: 'LIST', propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' } },
        { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
        { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' },
        { detailDataProp: 'sector', propTitle: this.$t('headers.sector'), propType: 'LIST', propRefs: { list: this.sectors, value: 'id', text: 'name' } },
        { detailDataProp: 'things', propTitle: this.$t('things'), propType: 'LIST', propRefs: { list: this.things, value: 'id', text: 'name' } }
      ]
      this.setDetails({
        detailData,
        dataStructure
      })
    },
    showSelectorMethod() {
      if (!this.showSelector) this.showSelector = true
    },
    showSelectorButton() {
      return this.selectedTabPosition === 'tab-position'
    },
    disableSelectorButton() {
      return this.selectorDisabled
    },
    tabSelectedPosition(id) {
      this.selectedTabPosition = id
      const positionReportRef = this
      const positionDetailRef = this.$refs['detail']
      if (id === 'tab-position' && positionReportRef && positionDetailRef) {
        this.commitVisible({ visible: false })
        positionDetailRef.loadingSpinner = false
        positionReportRef.loadingSpinner = true
        positionReportRef.$refs['PositionReportMapComponent'].change()
        // Se resetea el breadcrumb al volver al resumen
        this.tabsPosition = [
          {
            id: 'tab-position',
            name: i18n.t('dashboard.positionReport')
          }
        ]
        if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
          this.$store.state.app.entitySelectorItems = []
        }
      } else if (id === 'tab-position-detail' && positionReportRef && positionDetailRef) {
        this.commitVisible({ visible: false })
        positionDetailRef.loadingSpinner = true
        positionReportRef.loadingSpinner = false
        positionDetailRef.$refs['PositionDetailMapComponent'].change()
      }
    },
    /**
     * Método para actualizar el gráfico cuando cambian los rangos
     * @param {*} event
     */
    newRanges(event) {
      configurationService.save(this.componentId, { ranges: event.ranges })
      const secondChartComponentRef = this.$refs['secondChartComponent']
      this.loadingSpinnerDoughnut = true
      positionReportApi.getPositionReportNotDeferred(this.thingsId, this.timeStampFrom, this.timeStampTo, this.sectors, event.ranges)
        .then(async response => {
          const data = response.data
          const processedSummary = await positionReportService.processSummaryData(data, this.timeStampFrom, this.timeStampTo, this.chartComponentModel.rangeSelectorData[0])
          this.pieChart.cleanAndUpdate(processedSummary.chartVelocity)
          if (secondChartComponentRef) {
            this.pieChart.forEach(c => {
              secondChartComponentRef.reset(c)
            })
          }
          this.loadingSpinnerDoughnut = false
        })
    },
    async getConfiguration() {
      const config = await configurationService.get(this.componentId)
      if (config) {
        this.chartComponentModel.rangeSelectorData[0] = config.data.ranges
      } else {
        this.chartComponentModel.rangeSelectorData[0] = [
          {
            intervalStart: 0,
            intervalEnd: 50,
            color: '#FF0000'
          },
          {
            intervalStart: 50,
            intervalEnd: 100,
            color: '#00FF00'
          },
          {
            intervalStart: 100,
            intervalEnd: undefined,
            color: '#0000FF'
          }
        ]
      }
    }
  },
  watch: {
    selectorModel: {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
