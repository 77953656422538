/* eslint-disable indent */
import eventApi from '@/api/event.api'

export const eventService = (function () {
    'use strict'

    async function getEventsMapNameByThingsIds (ids) {
        const lang = localStorage.getItem('locale')
        const result = {}
        const events = await eventApi.getEventsByThingsIds(ids)
        if (events && events.length > 0) {
            events.forEach((event) => {
                result[event.number] = event.name[lang]
            })
        }
        return result
    }

    async function getInternalEventDescriptionMap () {
        const result = {}
        const events = await eventApi.getInternalEvents()
        if (events && events.length > 0) {
            events.forEach((event) => {
                result[event.key] = event.description
            })
        }
        return result
    }

    return {
        getEventsMapNameByThingsIds,
        getInternalEventDescriptionMap
    }
})()
