import personApi from '@/api/person.api'

export const personService = (function () {
  'use strict'

  async function getPersonMap () {
    let persons = null
    const result = {}
    try {
      persons = (await personApi.getAll()).data
    } catch (e) {
      console.debug(e)
    }
    if (persons && persons.length > 0) {
      persons.forEach((person) => {
        result[person.driverCode] = person.name
      })
    }
    return result
  }

  return {
    getPersonMap
  }
})()
