import service from '@/middleware'

const positionReportApi = {
  async getPositionReport (thingIds, from, to, ranges, route, filters) {
    const body = {
      reportQueryFilters: {
        thingIds,
        ranges,
        from,
        to,
        route
      },
      filters
    }
    const result = await service.http.post('/api/position/positions', body)
    return result
  },
  async getPositionReportNotDeferred (thingIds, from, to, sector, ranges) {
    const body = {
      thingIds,
      from,
      to,
      sector,
      ranges
    }
    const result = await service.http.post('/api/position/positions/not-deferred', body)
    return result
  },
  async getPositionReportDetail (thingIds, from, to) {
    const body = {
      thingIds,
      from,
      to
    }
    const result = await service.http.post('/api/position/positions-detail', body)
    return result
  }
}

export default positionReportApi
