import positionReportApi from '../api/positionReport.api'
import { getTimeOffset } from '@/tools/functions'
import moment from 'moment'
import i18n from '@/i18n'
import {
  Axes,
  Chart,
  ChartType,
  Dataset,
  DataType,
  FilterType,
  Options,
  ReferenceDto,
  ReportConversionUtil,
  Util,
  ValueText
} from '@colven/common-domain-lib/lib'
import positionReportConstants from '../constants/positionReportConstants'
import conversionUtil from '../utils/conversion.util'
import reportsUtil from '../utils/reports.util'
import stateApi from '../api/state.api'
import enterpriseApi from '../api/enterprise.api'

async function getPositionReportDeferred(thingIds, things, from, to, dateTimeRange, customDateTimeRangeType, sinceDate, sinceTime, toDate, toTime, sector, completeReport, filterSwitch, ranges, route) {
  const filters = {
    thingIds,
    things,
    sector,
    from,
    to,
    sinceDate,
    sinceTime,
    toDate,
    toTime,
    dateTimeRange,
    customDateTimeRangeType,
    route,
    completeReport,
    ranges,
    filterSwitch
  }
  try {
    await positionReportApi.getPositionReport(thingIds, from, to, ranges, route, filters)
  } catch (error) {
    console.error(error)
  }
  return null
}

const processSummaryData = async (data, from, to, ranges) => {
  const offset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const report = {}
  const headers = positionReportConstants.headers(lang)
  const mapData = await getMap(data, offset, lang)
  report.headers = headers
  report.maps = [mapData.map]
  report.data = mapData.details
  report.chartVelocity = [getDoughnutVelocity(mapData.data, from, to, lang, offset, ranges)]
  report.chartDistance = [getBarDistance(mapData.data, from, to, lang, offset)]
  return report
}

const processSummaryDataDetails = async (data, from, to) => {
  const offset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const report = {}
  const headers = positionReportConstants.headers(i18n, lang, true)
  const mapData = await getMap(data, offset, lang, true)
  addExtraHeaders(headers, mapData.headers)
  const customHeaders = await setEnterpriseConfiguration(headers, lang)
  report.headers = headers
  report.customHeaders = customHeaders
  report.maps = [mapData.map]
  report.data = mapData.details
  report.chartVelocity = [getDoughnutVelocity(mapData.data, from, to, lang, offset)]
  report.chartDistance = [getBarDistance(mapData.data, from, to, lang, offset)]
  return report
}

const setEnterpriseConfiguration = async (headers, lang) => {
  const config = await getReportConfiguration()
  if (config && config.data) {
    if (config.data.hideFields) {
      headers = headers.filter((header) => { return config.data.hideFields.includes(header.value) })
    }
    if (config.data.extraFields) {
      translateHeaders(config.data.extraFields, lang)
      config.data.extraFields.forEach(element => {
        if (!headers.some(x => x.value === element.value))
          headers.push(element)
      });
    }
    return headers
  }
  return null
}

const getReportConfiguration = () => {
  return enterpriseApi.getReportConfiguration(positionReportConstants.REPORT_CONFIGURATION_KEYS.POSITION)
}

const addExtraHeaders = (headers, extraHeaders) => {
  extraHeaders.forEach((header) => {
    headers.push({
      text: header,
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: positionReportConstants.NUMERIC_HEADERS.includes(header) ? DataType.number : DataType.string,
      value: header
    })
  })
}

const translateHeaders = (headers, lang) => {
  headers.forEach(h => {
    h.text = i18n.t(h.value, { lang })
  })
  return headers
}

const getMap = async (data, offset, lang, isForDetail = false) => {
  const references = []
  const details = []
  const headersSet = new Set()
  // MAP
  const seriesPositions = []

  // ids de estados para buscar sus datos
  let statesMapped = {}
  if (isForDetail) {
    let states = []
    data.forEach(element => {
      states = [...states, ...element.detail.map(d => d.state)]
    })
    states = [...new Set(states
      .filter(state => state != null && state._id != null && state._id !== '')
      .map(state => state._id))]

    // mapa id -> datos de los estados
    statesMapped = stateApi.getMultipleDataMapped(states, { color: 1, name: 1 })
  }
  data.forEach(d => {
    const serie = { type: 'LINE_MARKER', points: [], businessData: { _id: d.id, name: d.name } }
    // Color
    let color
    color = Util.pickColor([])
    const reference = new ReferenceDto()
    reference.name = d.name
    reference.color = color
    reference.isInterval = false
    reference.title = d.name
    references.push(reference)
    if (d.headers && Array.isArray(d.headers)) {
      for (const item of d.headers) {
        headersSet.add(item)
      }
    }

    // variables auxiliares
    let positionTimestampHtml
    let communicationTimestampHtml
    let engineState
    let engineStateColor
    let engineStateHTML
    let stateMapMarker
    let stateName
    let stateColor
    let state
    let rpm
    let speedAndDirection

    d.detail.forEach((detail, index) => {
      const { dateString, timeString } = ReportConversionUtil.applyTimezone(detail.trackTimestamp, offset)
      const communicationDate = ReportConversionUtil.applyTimezone(detail.timestamp, offset)
      detail.date = dateString
      detail.time = timeString
      const thingData = {
        number: index + 1,
        _id: d.id,
        name: d.name,
        date: dateString,
        time: timeString,
        position: new ValueText([detail.lat, detail.lng], JSON.stringify([detail.lat, detail.lng])),
        speed: {
          value: detail.speed ? +detail.speed.toFixed(2) : 0,
          text: ReportConversionUtil.speedToString(detail.speed, detail.direction)
        },
        direction: detail.direction,
        communicationDate: communicationDate.dateString,
        communicationTime: communicationDate.timeString,
        fromThingName: detail.fromThingName,
        typeName: detail.type != null && detail.type[lang] != null ? detail.type[lang] : '',
        ...detail.originalTrack
      }

      if (thingData['Edad Dato']) {
        thingData['Edad Dato'] = { value: parseFloat(thingData['Edad Dato']), text: thingData['Edad Dato'] }
      }
      if (thingData['Nivel Señal']) {
        thingData['Nivel Señal'] = { value: parseFloat(thingData['Nivel Señal']), text: thingData['Nivel Señal'] }
      }
      if (thingData['Temperatura']) {
        thingData['Temperatura'] = new ValueText(thingData['Temperatura'], conversionUtil.temperatureToStringTranslated(lang, thingData['Temperatura']))
      }
      if (thingData['RPM Motor']) {
        thingData['RPM Motor'] = { value: parseFloat(thingData['RPM Motor']), text: thingData['RPM Motor'] }
      }
      if (thingData['Tension Bateria']) {
        thingData['Tension Bateria'] = { value: parseFloat(thingData['Tension Bateria']), text: thingData['Tension Bateria'] }
      }
      if (thingData.RPM) { thingData.RPM = { value: parseFloat(thingData.RPM), text: thingData.RPM } }

      if (isForDetail) {
        positionTimestampHtml = ''
        if (detail.trackTimestamp != null) {
          const positionTimestampConversion = ReportConversionUtil.applyTimezone(detail.trackTimestamp, offset)
          const positionTimestamp = positionTimestampConversion.dateString + ' ' + positionTimestampConversion.timeString
          const positionTimestampColor = reportsUtil.getTimestampColor(detail.trackTimestamp)
          const positionTimestampName = i18n.t('mapReport.POSITION_DATE', { lang })
          // tslint:disable-next-line:max-line-length
          positionTimestampHtml = `${positionTimestampName}: <span style='color: ${positionTimestampColor} !important'>${positionTimestamp}</span>`
        }

        communicationTimestampHtml = ''
        if (detail.timestamp != null) {
          const comunicationTimestampConversion = ReportConversionUtil.applyTimezone(detail.timestamp, offset)
          const comunicationTimestamp = comunicationTimestampConversion.dateString + ' ' + comunicationTimestampConversion.timeString
          const comunicationTimestampColor = reportsUtil.getTimestampColor(detail.timestamp)
          const comunicationTimestampName = i18n.t('mapReport.COMUNICATION_DATE', { lang })
          // tslint:disable-next-line:max-line-length
          communicationTimestampHtml = `${comunicationTimestampName}: <span style='color: ${comunicationTimestampColor} !important'>${comunicationTimestamp}</span>`
        }

        engineState = detail.engineRunning === true
          ? i18n.t('mapReport.ENGINE_STATE.ON', { lang })
          : (detail.engineRunning === false ? i18n.t('mapReport.ENGINE_STATE.OFF', { lang }) : null)
        engineStateColor = detail.engineRunning === true ? '#66BB6A' : '#263238'
        engineStateHTML = engineState != null
          // tslint:disable-next-line:max-line-length
          ? `<span style='color: ${engineStateColor} !important'>${i18n.t('mapReport.ENGINE', { lang })} ${engineState}</span><br>`
          : ''

        state = detail.state != null && detail.state._id != null
          ? statesMapped[detail.state._id]
          : null
        stateMapMarker = ''
        stateName = null
        stateColor = 'none'
        if (state != null) {
          stateColor = state.color != null ? state.color : 'none'
          stateName = state.name != null && state.name[lang] != null
            ? state.name[lang]
            : null
        }
        if (stateName != null) {
          stateMapMarker = stateName != null
            ? `<span style='color: ${stateColor} !important text-transform: uppercase!important'>[${stateName}]</span>`
            : ''
        }

        speedAndDirection = conversionUtil.speedDetentionToStringTranslated(
          detail.speed, detail.direction, detail.stopped, detail.stoppedTime, detail.trackTimestamp, lang, true)

        rpm = null
        rpm = detail.rpm != null
          ? `<span>${detail.rpm} RPM</span><br>`
          : ''
      }

      serie.points.push({
        id: d.id,
        lat: detail.lat,
        lng: detail.lng,
        value: d.name,
        filters: [],
        marker: {
          type: 'fa-font',
          ref: 'fas fa-circle',
          title: isForDetail
            ? `<h4>${d.name} ${stateMapMarker}</h4>
            <hr>
            ${engineStateHTML}
            ${speedAndDirection.translation}
            <br>
            ${rpm}
            ${positionTimestampHtml}
            <br>
            ${communicationTimestampHtml}
            `
            : undefined,
          tooltipDirection: isForDetail ? 'right' : undefined,
          tooltipPermanent: isForDetail ? false : undefined,
          tooltipBorderColor: isForDetail ? stateColor : undefined,
          label: isForDetail
            ? `<span style="width:100%height:100%">${index + 1}</span>`
            : undefined,
          businessData: {
            number: index + 1,
            _id: d.id,
            name: d.name
          }
        }
      })

      details.push(thingData)
    })
    seriesPositions.push(serie)
  })

  const map = {
    id: 0,
    name: i18n.t('harvesterReport.dashboard.position.Positions', { lang }),
    groups: [
      {
        name: 'thingsPositions',
        title: i18n.t('harvesterReport.dashboard.position.Positions', { lang }),
        measure: undefined,
        referenceByInterval: false,
        series: [],
        references,
        exclusive: true,
        showMarkers: true,
        displayOnStart: true
      }
    ],
    filters: [],
    info: {}
  }
  map.groups[0].series = seriesPositions
  map.id = moment().unix()
  return { map, details, data, headers: Array.from(headersSet) }
}

const getDoughnutVelocity = (data, from, to, lang, offset, ranges = null) => {
  const yAxes = new Axes(ChartType.DOUGHNUT)
  const xAxes = new Axes(ChartType.DOUGHNUT)
  const options = new Options()
  options.tooltips.mode = 'point'
  const chart = new Chart()
  const dataset = new Dataset()
  let minumVelocity = 0
  let mediumVelocity = 0
  let maxVelocity = 0

  data.forEach(d => {
    d.detail.forEach(detail => {
      if (ranges && ranges.length > 0) {
        if (detail.speed > ranges[0].intervalStart && detail.speed <= ranges[0].intervalEnd) {
          ++minumVelocity
        } else if (ranges[1].intervalStart && detail.speed <= ranges[1].intervalEnd) {
          ++mediumVelocity
        } else if (ranges[2].intervalStart) {
          if (ranges[2].intervalEnd) {
            if (detail.speed <= ranges[2].intervalEnd) {
              ++maxVelocity
            }
          } else {
            ++maxVelocity
          }
        }
      } else {
        if (detail.speed > 0 && detail.speed <= 4) {
          ++minumVelocity
        } else if (detail.speed > 4 && detail.speed <= 8) {
          ++mediumVelocity
        } else if (detail.speed > 8) {
          ++maxVelocity
        }
      }
    })
  })
  // id
  /*
  const milliseconds = moment().unix()
  chart.id = `chartVelocity-${milliseconds}`
  */
  chart.id = 'positionReportChartVelocity'
  // nombre
  chart.name = i18n.t('harvesterReport.dashboard.position.velocityTitle', { lang }) +
    ' (' + i18n.t('harvesterReport.dashboard.position.measurements', { lang }) + ')'
  // tipo
  chart.type = ChartType.DOUGHNUT
  // labels
  const total = minumVelocity + mediumVelocity + maxVelocity
  const percentageMinum = Util.roundDecimals((minumVelocity / (total !== 0 ? total : 1)) * 100)
  const percentageMedium = Util.roundDecimals((mediumVelocity / (total !== 0 ? total : 1)) * 100)
  const percentageMax = Util.roundDecimals((maxVelocity / (total !== 0 ? total : 1)) * 100)
  const labelMinum = i18n.t('harvesterReport.dashboard.position.minVelocity',
    { lang }) + ' (' + percentageMinum + '%)'
  const labelMedium = i18n.t('harvesterReport.dashboard.position.mediumVelocity',
    { lang }) + ' (' + percentageMedium + '%)'
  const labelMax = i18n.t('harvesterReport.dashboard.position.maxVelocity',
    { lang }) + ' (' + percentageMax + '%)'
  chart.data.labels = [labelMinum, labelMedium, labelMax]
  // Dataset
  dataset.data = [minumVelocity, mediumVelocity, maxVelocity]
  dataset.label = ''
  yAxes.scaleLabel.labelString = ''
  // Color
  let pieColor

  dataset.backgroundColor = []
  if (ranges && ranges.length > 0) {
    ranges.forEach(r => {
      dataset.backgroundColor.push(r.color)
    })
  } else {
    for (let i = 0; i <= dataset.data.length - 1; i++) {
      pieColor = Util.pickColor(dataset.backgroundColor)
      dataset.backgroundColor.push(pieColor)
    }
  }

  dataset.borderWidth = 0
  // Agrego el dataset creado
  chart.data.datasets.push(dataset)
  // Options
  const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
  const toConversion = ReportConversionUtil.applyTimezone(to, offset)
  const title = fromConversion.dateString + ' ' + fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
  options.title.text = title
  xAxes.scaleLabel.labelString = ''
  options.scales.yAxes.push(yAxes)
  options.scales.xAxes.push(xAxes)
  chart.options = options
  return chart
}

const getBarDistance = (data, from, to, lang, offset) => {
  const dates = getDates(from, to)
  const yAxes = new Axes(ChartType.BAR)
  const xAxes = new Axes(ChartType.BAR)
  const options = new Options()
  const chart = new Chart()
  const datasets = new Map()

  // id
  /*
  const milliseconds = moment().unix()
  chart.id = `chartDistance-${milliseconds}`
  */
  chart.id = 'positionReportChartDistance'
  // nombre
  chart.name = i18n.t('harvesterReport.dashboard.position.DaysDistance', { lang }) +
    ' (' + i18n.t('harvesterReport.dashboard.position.km', { lang }) + ')'
  // tipo
  chart.type = ChartType.BAR

  data.forEach(d => {
    const dataSet = new Dataset()
    datasets.set(d.name, dataSet)
  })

  dates.forEach((date) => {
    datasets.forEach((value, key) => {
      const distance = getDistanceByDate(date, data, key)
      // if (parseFloat(distance) > 0) {

      value.data.push(parseFloat(distance))
      value.label = key
      value.originalData = [key]
      // labels

      if (!chart.data.labels.includes(date)) {
        chart.data.labels.push(date)
      }

      // }
    })
  })

  datasets.forEach((value, key) => {
    // Color
    let pieColor
    value.backgroundColor = []
    pieColor = Util.pickColor(value.backgroundColor)
    for (let i = 0; i <= value.data.length - 1; i++) {
      value.backgroundColor.push(pieColor)
    }

    value.borderWidth = 0
    value.datasetLabel = key
    if (value.data.length > 0) {
      chart.data.datasets.push(value)
    }
  })

  yAxes.scaleLabel.labelString = i18n.t('harvesterReport.dashboard.position.km', { lang })
  yAxes.stacked = true
  xAxes.stacked = true

  // Options
  const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
  const toConversion = ReportConversionUtil.applyTimezone(to, offset)
  const title = fromConversion.dateString + ' ' + fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString

  options.title.text = title
  xAxes.scaleLabel.labelString = i18n.t('harvesterReport.dashboard.position.Date', { lang })
  options.scales.yAxes.push(yAxes)
  options.scales.xAxes.push(xAxes)
  chart.options = options
  return chart
}

const getDates = (start, end) => {
  const dateArray = []
  const startDate = moment.unix(start).format('DD-MM-YY')
  const endDate = moment.unix(end).format('DD-MM-YY')
  let currentDate = moment(startDate, 'DD-MM-YYD')
  const stopDate = moment(endDate, 'DD-MM-YY')
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('DD-MM-YY'))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

const getDistanceByDate = (date, data, thing) => {
  const thingData = data.filter(d => d.name === thing)
  const posiciones = []
  let dateMomentFirstFormat
  let dateMomentSecondFormat
  thingData[0].detail.forEach(detail => {
    dateMomentFirstFormat = moment(detail.date, 'DD/MM/YYYY')
    dateMomentSecondFormat = moment(detail.date, 'DD-MM-YYYY')
    if (dateMomentFirstFormat.isSame(moment(date, 'DD-MM-YY')) || dateMomentSecondFormat.isSame(moment(date, 'DD-MM-YY'))) {
      posiciones.push({ latitud: detail.lat, longitud: detail.lng })
    }
  })
  return reportedCalculateDistance(posiciones)
}

const reportedCalculateDistance = (posiciones) => {
  let distanceTotal = 0
  for (let i = 0; i < posiciones.length - 1; i++) {
    distanceTotal += Util.distance(
      parseFloat(posiciones[i].latitud),
      parseFloat(posiciones[i].longitud),
      parseFloat(posiciones[i + 1].latitud),
      parseFloat(posiciones[i + 1].longitud),
      'K'
    )
  }

  return distanceTotal.toFixed(2)
}

export default {
  getPositionReportDeferred,
  processSummaryData,
  processSummaryDataDetails
}
